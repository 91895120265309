<template>
  <div
    class="flex flex-col w-full touch:md:flex-row lg:flex-row touch:md:px-sm lg:px-sm shrink-0"
  >
    <!-- Left col -->
    <div
      class="flex-initial max-w-[930px] w-full mx-auto pt-lg lg:pt-2xl touch:md:pt-2xl"
    >
      <div class="w-[315px] md:w-[455px] mx-auto">
        <div class="mt-xl mb-sm">
          <Headline
            :has-decoration="true"
            :headline="t('repairshopForm.addressForm.headline')"
            level="h1"
          />
        </div>

        <FormKit ref="addressData" type="group">
          <div class="mb-md lg:mb-xl touch:md:mb-xl">
            <FormKit
              id="company-name"
              v-model="formData.companyName"
              type="text"
              :label="t('repairshopForm.addressForm.companyLabel')"
              placeholder="Praxis Mustermann"
              input-class="input"
              inner-class="relative pt-[5px]"
              :validation-messages="{
                required: t('repairshopForm.addressForm.companyErrorMessage'),
              }"
              validation-visibility="blur"
              validation="required:trim"
              label-class="text-md !leading-[19px] font-bold"
            />
          </div>

          <!-- Address -->
          <FormCustomHeadline
            class="mb-sm"
            :headline="t('repairshopForm.addressForm.addressHeadline')"
            text-color="text-text-base font-deco-alt"
            border-color="border-text-base"
          />

          <div class="flex w-full px-0 mb-md lg:mb-xl touch:md:mb-xl">
            <div
              class="flex flex-wrap w-full pb-sm"
              :class="'hide-default-message'"
            >
              <FormKit
                id="address-street"
                ref="street"
                v-model="formData.addressData.street"
                type="text"
                :label="t('repairshopForm.addressForm.streetLabel')"
                placeholder="Hauptstr."
                input-class="input"
                outer-class="pr-2 md:pr-4 basis-9/12"
                validation="required:trim"
                validation-visibility="blur"
                label-class="text-md !leading-[19px] font-bold"
                inner-class="pt-[5px]"
              />

              <FormKit
                id="address-housenumber"
                ref="houseNumber"
                v-model="formData.addressData.houseNumber"
                type="text"
                :label="t('repairshopForm.addressForm.houseNrLabel')"
                placeholder="123"
                input-class="input"
                outer-class="basis-3/12"
                validation="required:trim"
                validation-visibility="blur"
                label-class="text-md !leading-[19px] font-bold"
                inner-class="pt-[5px]"
              />

              <template v-if="showStreetError">
                <FormCustomMessage
                  :message="t('repairshopForm.addressForm.streetErrorMessage')"
                />
              </template>

              <FormKit
                id="address-zip-code"
                ref="zipCode"
                v-model="formData.addressData.zipCode"
                type="text"
                :label="t('repairshopForm.addressForm.zipLabel')"
                :placeholder="getPlaceholderZipCode()"
                :validation="zipCodeValidationRules"
                validation-visibility="blur"
                input-class="input"
                :outer-class="'basis-3/12 mt-2'"
                inner-class="pt-[5px]"
                label-class="text-md !leading-[19px] font-bold"
              />

              <FormKit
                id="address-city"
                ref="city"
                v-model="formData.addressData.city"
                type="text"
                :label="t('repairshopForm.addressForm.cityLabel')"
                placeholder="Musterstadt"
                input-class="input"
                :outer-class="'basis-9/12 pl-2 md:pl-4 mt-2'"
                validation="required:trim"
                validation-visibility="blur"
                label-class="text-md !leading-[19px] font-bold"
                inner-class="pt-[5px]"
              />

              <template v-if="showZipCodeError">
                <FormCustomMessage
                  :message="t('repairshopForm.addressForm.zipErrorMessage')"
                />
              </template>
            </div>
          </div>

          <!-- Contact data -->
          <FormCustomHeadline
            class="mb-sm"
            :headline="t('repairshopForm.addressForm.contactDataHeadline')"
            text-color="font-deco-alt text-text-base"
            border-color="border-text-base"
          />

          <div class="mb-sm">
            <FormKit
              id="customer-number"
              v-model="formData.contactData.customerNumber"
              type="text"
              :label="t('repairshopForm.addressForm.customerNumber')"
              placeholder="123-456-789"
              input-class="input"
              inner-class="relative pt-[5px]"
              label-class="text-md !leading-[19px] font-bold"
            />
          </div>

          <div class="mb-sm">
            <FormKit
              id="contact-mail"
              v-model="formData.contactData.customerEmail"
              type="email"
              :label="t('repairshopForm.addressForm.emailLabel')"
              placeholder="max@mustermann.de"
              :validation-messages="{
                required: t('repairshopForm.addressForm.emailErrorMessage'),
                validation: t('repairshopForm.addressForm.emailErrorMessage'),
              }"
              validation-visibility="blur"
              validation="required|email"
              label-class="text-md !leading-[19px] font-bold"
              inner-class="relative pt-[5px]"
            />
          </div>

          <div class="mb-md lg:mb-xl touch:md:mb-xl">
            <Phone
              id="contact-phone"
              v-model="formData.contactData.customerPhone"
              name="phone"
              :label="t('repairshopForm.addressForm.phoneLabel')"
              placeholder="+49 123 456789"
              :validation="'required:trim'"
              :required-message="
                t('repairshopForm.addressForm.phoneErrorMessage')
              "
              :matches-message="
                t('repairshopForm.addressForm.phoneErrorMessage')
              "
              :required="true"
              input-class="input"
              label-class="text-md !leading-[19px] font-bold"
              inner-class="relative pt-[5px]"
            />
          </div>

          <div class="pt-md lg:pt-xl touch:md:pt-xl mb-sm">
            <Headline
              :has-decoration="true"
              :headline="t('repairshopForm.addressForm.deliveryHeadline')"
              level="h2"
            />
          </div>

          <div class="mb-md lg:mb-xl touch:md:mb-xl">
            <div class="mb-xs">
              <input
                id="differentDeliveryAddress-no"
                v-model="formData.differentDeliveryAddress"
                class="custom-radio"
                type="radio"
                :value="false"
              />
              <label for="differentDeliveryAddress-no">
                {{ t('repairshopForm.addressForm.differentDeliveryAddressNo') }}
              </label>
            </div>

            <div>
              <input
                id="differentDeliveryAddress-yes"
                v-model="formData.differentDeliveryAddress"
                class="custom-radio"
                type="radio"
                :value="true"
              />
              <label for="differentDeliveryAddress-yes">
                {{
                  t('repairshopForm.addressForm.differentDeliveryAddressYes')
                }}
              </label>
            </div>
          </div>

          <div v-if="formData.differentDeliveryAddress">
            <div class="mb-md lg:mb-lg touch:md:mb-lg">
              <FormKit
                id="company-name"
                v-model="formData.deliveryData.companyName"
                type="text"
                :label="t('repairshopForm.addressForm.companyLabel')"
                placeholder="Praxis Mustermann"
                input-class="input"
                inner-class="relative pt-[5px]"
                :validation-messages="{
                  required: t('repairshopForm.addressForm.companyErrorMessage'),
                }"
                validation-visibility="blur"
                validation="required:trim"
                label-class="text-md !leading-[19px] font-bold"
              />
            </div>

            <!-- Address -->
            <form-custom-headline
              class="mb-sm"
              :headline="t('repairshopForm.addressForm.addressHeadline')"
              text-color="text-text-base"
              border-color="border-text-base"
            />

            <div class="flex w-full px-0">
              <div
                class="flex flex-wrap w-full pb-xl"
                :class="'hide-default-message'"
              >
                <FormKit
                  id="address-street"
                  ref="streetDelivery"
                  v-model="formData.deliveryData.addressData.street"
                  type="text"
                  :label="t('repairshopForm.addressForm.streetLabel')"
                  placeholder="Hauptstr."
                  input-class="input"
                  outer-class="pr-2 mt-2 md:pr-4 basis-9/12"
                  validation="required:trim"
                  validation-visibility="blur"
                  label-class="text-md !leading-[19px] font-bold"
                  inner-class="pt-[5px]"
                />

                <FormKit
                  id="address-housenumber"
                  ref="houseNumberDelivery"
                  v-model="formData.deliveryData.addressData.houseNumber"
                  type="text"
                  :label="t('repairshopForm.addressForm.houseNrLabel')"
                  placeholder="123"
                  input-class="input"
                  outer-class="mt-2 basis-3/12"
                  validation="required:trim"
                  validation-visibility="blur"
                  label-class="text-md !leading-[19px] font-bold"
                  inner-class="pt-[5px]"
                />

                <template v-if="showStreetErrorDelivery">
                  <FormCustomMessage
                    :message="
                      t('repairshopForm.addressForm.streetErrorMessage')
                    "
                  />
                </template>

                <FormKit
                  id="address-zip-code"
                  ref="zipCodeDelivery"
                  v-model="formData.deliveryData.addressData.zipCode"
                  type="text"
                  :label="t('repairshopForm.addressForm.zipLabel')"
                  :placeholder="t('repairshopForm.addressForm.zipPlaceholder')"
                  :validation="zipCodeValidationRules"
                  validation-visibility="blur"
                  input-class="input"
                  :outer-class="'basis-3/12 mt-2'"
                  inner-class="pt-[5px]"
                  label-class="text-md !leading-[19px] font-bold"
                />

                <FormKit
                  id="address-city"
                  ref="cityDelivery"
                  v-model="formData.deliveryData.addressData.city"
                  type="text"
                  :label="t('repairshopForm.addressForm.cityLabel')"
                  placeholder="Musterstadt"
                  input-class="input"
                  :outer-class="'basis-9/12 pl-2 md:pl-4 mt-2'"
                  validation="required:trim"
                  validation-visibility="blur"
                  label-class="text-md !leading-[19px] font-bold"
                  inner-class="pt-[5px]"
                />

                <template v-if="showZipCodeErrorDelivery">
                  <FormCustomMessage
                    :message="t('repairshopForm.addressForm.zipErrorMessage')"
                  />
                </template>
              </div>
            </div>
          </div>
        </FormKit>
      </div>
    </div>

    <!-- Pricebox -->
    <RepairshopFieldPriceBox class="mb-xl touch:md:mb-0 lg:mb-0">
      <div class="md:mb-[130px]">
        <BasicButton
          classes="w-full bottom-0 z-10 lg:z-auto touch:md:z-auto fixed bottom-0 left-0 right-0 lg:relative touch:md:relative font-deco-alt"
          :label="t('repairshopForm.continue')"
          :type="EButtonType.BUTTON"
          :btn-style="EButtonStyle.CTA"
          :loading-animation="isLoading"
          :disabled="isLoading"
          :no-border-radius="!isDesktop"
          :btn-height="isDesktop ? '' : 'var(--sticky-btn-height)'"
          full-width
          @click="checkValidity"
        />
      </div>
    </RepairshopFieldPriceBox>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import { Headline } from '@/complib';
import FormCustomMessage from '@/components/form/custom-message.vue';
import FormCustomHeadline from '@/components/form/custom-headline.vue';
import RepairshopFieldPriceBox from '~/components/form/repairshop/components/price/tile-view.vue';
import BasicButton from '~~/src/components/components/core/basic-button/basic-button.vue';
import { FormKit } from '@formkit/vue';
import { SiteIdent } from '~/utils/types';

const Phone =
  useSiteIdent() === SiteIdent.default
    ? defineAsyncComponent(() => import('@/complib/formFields/phone/phone.vue'))
    : defineAsyncComponent(
        () =>
          import(
            '@/components/form/repairshop/components/form-fields/minilu/custom-phone.vue'
          ),
      );

const languageCode = useNuxtApp().$languageCode;
const { t } = useTrans();
const isDesktop = useIsDesktopView();
const isLoading = ref(false);
const { formData } = await useFormData();
const addressData = ref(null);
const street = ref(null);
const houseNumber = ref(null);
const zipCode = ref(null);
const city = ref(null);
const streetDelivery = ref(null);
const houseNumberDelivery = ref(null);
const zipCodeDelivery = ref(null);
const cityDelivery = ref(null);

onBeforeMount(() => {
  if (import.meta.client) {
    window.scrollTo(0, 0);
  }
});

const showStreetError = computed(() => {
  if (!street.value || !houseNumber.value) {
    return false;
  }

  const streetState = street.value.node.context.state;
  const houseNumberState = houseNumber.value.node.context.state;

  return (
    (!streetState.valid || !houseNumberState.valid) &&
    (streetState.blurred ||
      streetState.submitted ||
      houseNumberState.blurred ||
      houseNumberState.submitted)
  );
});

const showStreetErrorDelivery = computed(() => {
  if (!streetDelivery.value || !houseNumberDelivery.value) {
    return false;
  }

  const streetState = streetDelivery.value.node.context.state;
  const houseNumberState = houseNumberDelivery.value.node.context.state;

  return (
    (!streetState.valid || !houseNumberState.valid) &&
    (streetState.blurred ||
      streetState.submitted ||
      houseNumberState.blurred ||
      houseNumberState.submitted)
  );
});

const showZipCodeError = computed(() => {
  if (!zipCode.value || !city.value) {
    return false;
  }

  const zipCodeState = zipCode.value.node.context.state;
  const cityState = city.value.node.context.state;

  return (
    (!zipCodeState.valid || !cityState.valid) &&
    (zipCodeState.blurred ||
      zipCodeState.submitted ||
      cityState.blurred ||
      cityState.submitted)
  );
});

const showZipCodeErrorDelivery = computed(() => {
  if (!zipCodeDelivery.value || !cityDelivery.value) {
    return false;
  }

  const zipCodeState = zipCodeDelivery.value.node.context.state;
  const cityState = cityDelivery.value.node.context.state;

  return (
    (!zipCodeState.valid || !cityState.valid) &&
    (zipCodeState.blurred ||
      zipCodeState.submitted ||
      cityState.blurred ||
      cityState.submitted)
  );
});

const zipCodeValidationRules = computed(() => {
  if (languageCode === 'de' || useSiteIdent() === SiteIdent.default) {
    return 'required|matches:/^[0-9]{5}$/';
  } else if (languageCode === 'at') {
    return 'required|matches:/^[0-9]{4}$/';
  } else if (languageCode === 'nl') {
    return 'required|matches:/^[1-9][0-9]{3}\\s?([a-zA-Z]{2})?$/';
  } else {
    return '';
  }
});

function checkValidity() {
  isLoading.value = true;

  let invalidElements: any[] = [];

  // We need to set submitted state to true for all repeater fields
  // Otherwise field will not be validated
  const test = getInvalidElements(addressData.value.node.children);
  invalidElements = invalidElements.concat(test);

  if (!addressData.value.node.context.state.valid) {
    const firstInvalidFieldNode = document
      .getElementById(invalidElements[0].context.id)
      .closest('.formkit-outer');

    if (firstInvalidFieldNode) {
      firstInvalidFieldNode.scrollIntoView({
        behavior: 'smooth',
      });
    }
    isLoading.value = false;
    return;
  }

  isLoading.value = false;

  useRouter().push({
    path: useRouter().currentRoute.value.path,
    hash: '#repairshop-overview',
    query: { sourceUrl: useRouter().currentRoute.value.query['sourceUrl'] },
  });
}

function getInvalidElements(fields: any[], invalidElements: any[] = []) {
  fields.forEach((field: any) => {
    field.context.state.submitted = true;

    // If field is field group, we need to check all children
    if (field.context.type === 'group') {
      const invalidChildElements = getInvalidElements(
        field.children,
        invalidElements,
      );
      invalidElements.push(invalidChildElements);
      return invalidElements;
    }

    if (!field.context.state.valid) {
      invalidElements.push(field);
    }

    return invalidElements;
  });

  return invalidElements;
}

function getPlaceholderZipCode(): string {
  switch (languageCode) {
    case 'de':
      return '12345';
    case 'nl':
      return '1234AA';
    case 'at':
      return '1234';
    default:
      return '12345';
  }
}
</script>

<style lang="postcss">
/* remove standard-styles */
.custom-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  font-size: 1em;
  width: 100%;
}

/* graceful degradation for ie8 */
.custom-radio {
  width: auto;
  float: left;
  margin-right: 0.75em;
  background: transparent;
  border: none;
}

.custom-radio:checked,
.custom-radio:not(:checked) {
  background: transparent;
  position: relative;
  visibility: hidden;
  margin: 0;
  padding: 0;
}

.custom-radio + label {
  cursor: pointer;
}

.custom-radio:checked + label::before,
.custom-radio:not(:checked) + label::before {
  content: ' ';
  display: inline-block;
  width: 17px;
  height: 17px;
  position: relative;
  top: 4px;
  border: 1px solid #cbcbcb;
  background: #fff;
  margin-right: 1em;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.custom-radio:checked + label::before,
.custom-radio:not(:checked) + label::before {
  border-radius: 3px;
}

.custom-radio:hover + label::before {
  background: #ddd;
  box-shadow: inset 0 0 0 2px white;
}

.custom-radio:checked + label::before {
  box-shadow: inset 0 0 0 3px white;
}

.custom-radio:checked + label::after {
  content: ' ';
  display: block;
  width: 11px;
  height: 11px;
  position: relative;
  top: -19px;
  background: var(--thm-primary-base);
  left: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
</style>

<style lang="postcss" scoped>
:deep(.hide-default-message .formkit-outer .formkit-messages) {
  @apply !hidden;
}

:deep([data-invalid] .formkit-input) {
  @apply border-b-sign-red !border-b-[3px] z-500;
}
</style>
