import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import type { RepairshopFormType } from '~/components/form/repairshop/utils/formTypes';
import { RepairshopStepEnum } from '~/components/form/repairshop/utils/formStepEnum';
import { useLoadForm } from '~~/src/composables/dataFetching/cms/form';

export async function loadForm(formHandle: string) {
  const { data: fetchData } = await useLoadForm(formHandle);

  return fetchData.value;
}

export async function isFormValidForStep(
  step: RepairshopStepEnum,
): Promise<boolean> {
  const { formData } = await useFormData();

  switch (step) {
    case RepairshopStepEnum.BASIC:
      return isBasicDataValid(formData.value);
    case RepairshopStepEnum.ADDRESS:
      return (
        isBasicDataValid(formData.value) && isCheckoutDataValid(formData.value)
      );
  }
}

function isBasicDataValid(formData: RepairshopFormType): boolean {
  const isValid = [] as boolean[];
  formData.tools.forEach((tool) => {
    isValid.push(
      !!(tool.manufacturer && tool.device && tool.infoText.length !== 0),
    );
  });

  return !isValid.includes(false);
}

function isCheckoutDataValid(formData: RepairshopFormType): boolean {
  const isValid = [] as boolean[];
  isValid.push(
    formData.companyName.length > 0 &&
      formData.addressData.street.length > 0 &&
      formData.addressData.houseNumber.length > 0 &&
      formData.addressData.zipCode.length > 0 &&
      formData.addressData.city.length > 0,
  );

  if (formData.differentDeliveryAddress) {
    isValid.push(
      formData.deliveryData.companyName.length > 0 &&
        formData.deliveryData.addressData.street.length > 0 &&
        formData.deliveryData.addressData.houseNumber.length > 0 &&
        formData.deliveryData.addressData.zipCode.length > 0 &&
        formData.deliveryData.addressData.city.length > 0,
    );
  }
  return !isValid.includes(false);
}
