import type {
  OptionType,
  OptionDataType,
} from '~/components/form/repairshop/utils/formTypes';

export const useRepairshopData = () =>
  useState<Record<string, any>>('repairshopData', () => ({
    manufacturers: {
      data: {} as Record<string, OptionType[]>,
      isLoading: false,
      isInitialized: false,
      error: false,
    } as OptionDataType,
    devices: {
      data: {} as Record<string, OptionType[]>,
      isLoading: false,
      isInitialized: false,
      error: false,
    } as OptionDataType,
  }));
