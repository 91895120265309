<template>
  <FormSelectMultiselect
    :id="context.id"
    :placeholder="context.placeholder"
    :value="context.value"
    :disabled="context.disabled"
    :initial-options="context.initialOptions"
    :is-loading="context.isLoading"
    :sublabel="context.sublabel"
    :searchable="false"
    @on-value-changed="handleValueChanged"
  ></FormSelectMultiselect>
</template>

<script setup lang="ts">
import FormSelectMultiselect from '@/components/form/select/multiselect.vue';

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object,
});

function handleValueChanged(event: any) {
  props.context.node.input(event);
}
</script>
