import { v4 as uuidv4 } from 'uuid';
import type { RepairshopFormType } from '~/components/form/repairshop/utils/formTypes';
import { useUserAddress } from '@/stores/useUserAddress';
import { useUserContext } from '@/stores/useUserContext';
import { useSessionStore } from '~/stores/useSessionStore';

async function getPrefillData(isLoggedIn: boolean) {
  const userFormData = {
    zip: '',
    city: '',
    street: '',
    houseNumber: '',
    phoneNumber: '',
    phoneCountry: '',
    companyName: '',
    fullphoneNumber: '',
    customerNumber: '',
    email: '',
  };

  if (useShopEnabled() && isLoggedIn) {
    const userAddresses = useUserAddress();
    await userAddresses.loadAddresses();

    const allUserData = useUserContext();
    await allUserData.loadAccountData();
    if (userAddresses.contactAddress) {
      userFormData.zip = userAddresses.contactAddress.zip;
      userFormData.street = userAddresses.contactAddress.street;
      userFormData.houseNumber = userAddresses.contactAddress.number;
      userFormData.city = userAddresses.contactAddress.city;
    }
    if (allUserData.accountData) {
      userFormData.fullphoneNumber = allUserData.accountData.phoneNumber;
      if (allUserData.accountData.phoneNumber?.startsWith('+')) {
        userFormData.phoneCountry = allUserData.accountData.phoneNumber.slice(
          0,
          3,
        );
        userFormData.phoneNumber = allUserData.accountData.phoneNumber.slice(3);
      } else {
        userFormData.phoneCountry = undefined;
        userFormData.phoneNumber = allUserData.accountData.phoneNumber;
      }

      userFormData.customerNumber = allUserData.accountData.customerNumber;
    }
    if (allUserData.userData) {
      userFormData.email = allUserData.userData.email;
      userFormData.companyName = `${allUserData.userData.name1} ${allUserData.userData.name2}`;
    }
  }
  return userFormData;
}

export const useFormData = async () => {
  const userFormData = await getPrefillData(useSessionStore().isLoggedIn);

  const formData = useState<RepairshopFormType>('formData', () => ({
    isFormSubmitting: false,
    tools: [
      {
        id: uuidv4() as string,
        manufacturer: null,
        device: null,
        serialNumber: '',
        infoText: '',
      },
    ],
    companyName: userFormData.companyName ?? '',
    addressData: {
      street: userFormData.street ?? '',
      houseNumber: userFormData.houseNumber ?? '',
      zipCode: userFormData.zip ?? '',
      city: userFormData.city ?? '',
    },
    contactData: {
      customerNumber: userFormData.customerNumber ?? '',
      customerEmail: userFormData.email ?? '',
      customerPhone: userFormData.fullphoneNumber ?? '',
      phoneCountry: userFormData.phoneCountry ?? '',
      phoneNumber: userFormData.phoneNumber ?? '',
      contactPhone: userFormData.phoneNumber ?? '',
    },
    differentDeliveryAddress: false,
    deliveryData: {
      companyName: '',
      addressData: {
        street: '',
        houseNumber: '',
        zipCode: '',
        city: '',
      },
    },
  }));

  const resetFormData = () => {
    formData.value.isFormSubmitting = false;
    formData.value.tools = [
      {
        id: uuidv4() as string,
        manufacturer: null,
        device: null,
        serialNumber: '',
        infoText: '',
      },
    ];
    formData.value.companyName = '';
    formData.value.addressData.street = '';
    formData.value.addressData.houseNumber = '';
    formData.value.addressData.zipCode = '';
    formData.value.addressData.city = '';
    formData.value.contactData.customerNumber = '';
    formData.value.contactData.customerEmail = '';
    formData.value.contactData.customerPhone = '';
    formData.value.contactData.phoneNumber = '';
    formData.value.differentDeliveryAddress = false;
    formData.value.deliveryData.companyName = '';
    formData.value.deliveryData.addressData.street = '';
    formData.value.deliveryData.addressData.houseNumber = '';
    formData.value.deliveryData.addressData.zipCode = '';
    formData.value.deliveryData.addressData.city = '';
  };

  return {
    formData,
    resetFormData,
  };
};
