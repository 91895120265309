<template>
  <div>
    <p :id="id" ref="textNode" class="line-clamp-2 break-all italic">
      {{ text }}
    </p>
    <a
      v-if="isTextExpandable"
      class="cursor-pointer pt-3"
      :class="textColor"
      @click="expandText()"
    >
      <span>{{ expandableLinkText }}</span>
      <FaIcon
        :classes="`pl-2 ${isTextExpanded ? 'rotate-180' : 'transform-none'}`"
        icon-class="fal fa-angle-down"
      />
    </a>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
defineProps({
  id: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  textColor: {
    type: String,
    required: false,
    default: 'text-text-base',
  },
});

const textNode = ref(null);
const isTextExpandable = ref(false);
const isTextExpanded = ref(false);

onMounted(() => {
  if (!textNode.value) {
    return;
  }

  isTextExpandable.value =
    textNode.value.offsetHeight < textNode.value.scrollHeight ||
    textNode.value.offsetWidth < textNode.value.scrollWidth;
});

const expandableLinkText = computed(() => {
  return isTextExpanded.value ? 'Weniger anzeigen' : 'Mehr anzeigen';
});

function expandText() {
  if (textNode.value.classList.contains('line-clamp-2')) {
    textNode.value.classList.remove('line-clamp-2');
  } else {
    textNode.value.classList.add('line-clamp-2');
  }

  isTextExpanded.value = !isTextExpanded.value;
}
</script>

<style lang="postcss" scoped>
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
