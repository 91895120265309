<template>
  <div id="repairshop-checkout">
    <div
      :class="[
        'h-[32px] md:h-[46px]',
        {
          'bg-[#635652]': useSiteIdent() === SiteIdent.default,
          'bg-[#AA5A9F]': useSiteIdent() !== SiteIdent.default,
        },
      ]"
    />
    <div class="relative">
      <div
        ref="progressBar"
        :class="[
          'w-full h-[8px] absolute',
          {
            'bg-[#D0CCCA]': useSiteIdent() === SiteIdent.default,
            'bg-[#E5CDE2]': useSiteIdent() !== SiteIdent.default,
          },
        ]"
      />
      <div
        class="h-[8px] bg-primary-base absolute"
        :style="`width: ${progressWidth}px`"
      />
    </div>

    <ContainerBasic container-type="PAGE">
      <div
        class="flex flex-col lg:flex-row touch:md:flex-row lg:px-sm touch:md:px-sm shrink-0 w-full t-[8px]"
      >
        <div class="flex-initial max-w-[930px] w-full mx-auto absolute">
          <!-- Progressbar -->
          <div class="w-[315px] md:w-[455px] mx-auto">
            <div class="px-xs flex justify-between -translate-y-[28%]">
              <!-- Icon 1 -->
              <div ref="addressIcon" class="text-center">
                <div
                  class="flex justify-center items-center w-[50px] h-[50px] md:w-[70px] md:h-[70px] rounded-full bg-white mb-sm"
                >
                  <div
                    class="flex justify-center items-center w-[46px] h-[46px] md:w-[66px] md:h-[66px] rounded-full bg-primary-base"
                  >
                    <Icon
                      classes="text-white !w-[26px] !h-[26px]"
                      icon-class="fa-solid fa-location-dot"
                    />
                  </div>
                </div>
                <span
                  class="pt-sm text-text-base"
                  :class="{ 'font-bold !text-text-base': !isOverviewStep }"
                >
                  Adresse
                </span>
              </div>
              <!-- Icon 2 -->
              <div ref="overviewIcon" class="text-center">
                <div
                  class="flex justify-center items-center w-[50px] h-[50px] md:w-[70px] md:h-[70px] rounded-full bg-white mb-sm"
                  :class="{
                    'border-2 border-solid': !isOverviewStep,
                    'border-[#D0CCCA]':
                      !isOverviewStep && useSiteIdent() === SiteIdent.default,
                    'border-[#E5CDE2]':
                      !isOverviewStep && useSiteIdent() !== SiteIdent.default,
                  }"
                >
                  <div
                    class="flex justify-center items-center w-[46px] h-[46px] md:w-[66px] md:h-[66px] rounded-full"
                    :class="{
                      'bg-primary-base': isOverviewStep,
                    }"
                  >
                    <template v-if="isOverviewStep">
                      <Icon
                        classes="text-white !w-[26px] !h-[26px]"
                        icon-class="fa-solid fa-magnifying-glass-arrow-right"
                      />
                    </template>
                    <template v-else>
                      <Icon
                        :classes="`${
                          useSiteIdent() === SiteIdent.default
                            ? 'text-[#635652]'
                            : 'text-[#AA5A9F]'
                        } !w-[26px] !h-[26px]`"
                        icon-class="fa-light fa-magnifying-glass-arrow-right"
                      />
                    </template>
                  </div>
                </div>
                <span class="pt-sm" :class="{ 'font-bold': isOverviewStep }">
                  {{ t('repairshopForm.check') }}
                </span>
              </div>
              <!-- Icon 3 -->
              <div class="text-center">
                <div
                  class="flex justify-center items-center w-[50px] h-[50px] md:w-[70px] md:h-[70px] rounded-full bg-white border-2 border-solid mb-sm"
                  :class="{
                    'border-[#D0CCCA]': useSiteIdent() === SiteIdent.default,
                    'border-[#E5CDE2]': useSiteIdent() !== SiteIdent.default,
                  }"
                >
                  <Icon
                    :classes="`${
                      useSiteIdent() === SiteIdent.default
                        ? 'text-[#635652]'
                        : 'text-[#AA5A9F]'
                    } !w-[26px] !h-[26px]`"
                    icon-class="fa-light fa-badge-check"
                  />
                </div>
                <span class="mt-sm">{{ t('repairshopForm.finish') }}!</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Placeholder for pricebox col -->
        <div
          class="flex-initial max-w-[451px] shrink-0 w-full mx-auto px-md"
        ></div>
      </div>

      <!-- Form -->
      <div>
        <RepairshopStepAddressForm v-if="!isOverviewStep" />
        <RepairshopStepOverview v-else />
      </div>
    </ContainerBasic>
  </div>
</template>

<script setup lang="ts">
import { isFormValidForStep } from '~/components/form/repairshop/utils/form';
import { RepairshopStepEnum } from '~/components/form/repairshop/utils/formStepEnum';
import { useResizeObserver } from '@vueuse/core';
import RepairshopStepOverview from '~/components/form/repairshop/components/form-steps/overview.vue';
import RepairshopStepAddressForm from '~/components/form/repairshop/components/form-steps/address.vue';
import ContainerBasic from '@/components/components/container-basic.vue';
import { Icon } from '@/complib';
import { SiteIdent } from '~/utils/types';

const { t } = useTrans();
const pageData = usePageData();
const progressBar = ref(null);
const addressIcon = ref(null);
const overviewIcon = ref(null);
const progressWidth = ref(0);
const props = defineProps({
  submittedSuccessfully: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const isOverviewStep = computed(() => {
  return (
    useRouter().currentRoute.value.hash === '#repairshop-overview' ||
    props.submittedSuccessfully
  );
});
const stepEnum = computed(() => {
  return isOverviewStep.value
    ? RepairshopStepEnum.ADDRESS
    : RepairshopStepEnum.BASIC;
});

onMounted(() => {
  if (!isFormValidForStep(stepEnum.value)) {
    useRouter().replace({
      path: '/' + pageData.value.temp_raw.parent.uri,
      hash: '',
    });
  }

  setProgressBarWidth();
});

watch(
  () => useRouter().currentRoute.value.hash,
  (newHash) => {
    if (newHash.length > 0 && !isFormValidForStep(stepEnum.value)) {
      useRouter().replace({
        path: '/' + pageData.value.temp_raw.parent.uri,
        hash: '',
      });
    }

    setProgressBarWidth();
    if (import.meta.client) {
      window.scrollTo({ top: 0 });
    }
  },
);

useResizeObserver(progressBar, () => {
  setProgressBarWidth();
});

function setProgressBarWidth() {
  if (isOverviewStep.value) {
    progressWidth.value = overviewIcon.value.getBoundingClientRect().left + 25;
  } else {
    progressWidth.value = addressIcon.value.getBoundingClientRect().left + 25;
  }
}
</script>
