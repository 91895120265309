import type { TFormMutationOptions } from '~~/src/server/utils/cms/formMutation';

export async function submit(
  formHandle: string,
  state: any,
  formData: any,
  site: string,
): Promise<any> {
  const mutationOptions = {} as TFormMutationOptions;
  if (state.captchas && state.captchas.googleCaptcha) {
    mutationOptions.googleCaptchaV3Token =
      await state.captchas.googleCaptcha.execute('submit');
  }

  let response = null;

  await useSubmitForm(
    formHandle,
    formData,
    mutationOptions.googleCaptchaV3Token,
    site,
  )
    .then(async (result) => {
      //@HACK: Captcha Errors just return and empty form object like {"formHandleHere":null} so that's the only thing to check at the moment
      const dataError = (<any>result?.data?.value)?.error;
      const data = result.data.value;
      if (
        result?.error?.value == undefined &&
        data[Object.keys(data)[0]] !== null
      ) {
        response = data;
      } else if (result?.error || dataError) {
        //@todo trigger some error handling + user feedback
        // eslint-disable-next-line no-console
        console.error('Form: General Error ', result.error ?? dataError);
        useElasticApm()?.captureError({
          name: 'Form: General Error',
          message: 'Form: General Error',
          cause: JSON.stringify(result?.error ?? dataError ?? {}),
        });
        window.alert(
          'Formular Absende-Fehler - bitte Formular erneut abschicken',
        );
      } else {
        // eslint-disable-next-line no-console
        console.error('Form: Captcha Error ', result.data);
        window.alert('Formular Absende-Fehler - Captcha ungültig');
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
      useElasticApm()?.captureError(e);
      window.alert(
        'Formular Absende-Fehler - bitte Formular erneut abschicken',
      );
    });

  return response;
}
