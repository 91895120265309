<template>
  <div>
    <!-- JS - Animation - eigene Component -->
    <ContainerBasic v-if="isBasicStep" container-type="FULL_WIDTH">
      <div class="!bg-activesection-alpha text-text-base h-fit w-full mx-auto">
        <FormRepairshopJsAnimation></FormRepairshopJsAnimation>
      </div>
    </ContainerBasic>

    <!-- Preisliste - eigene Component -->
    <ContainerBasic
      v-if="isBasicStep"
      class="mt-xl md:mt-3xl"
      container-type="PAGE"
    >
      <div class="!bg-white text-text-base pb-xl">
        <FormRepairshopPricelist></FormRepairshopPricelist>
      </div>
    </ContainerBasic>

    <!-- Formular -->
    <div v-if="state.isLoading">
      <ContainerBasic container-type="PAGE">
        <LoaderForm></LoaderForm>
      </ContainerBasic>
    </div>
    <div v-else>
      <ClientOnly>
        <ContainerBasic
          v-if="
            state.submittedSuccessfully ||
            (!state.isLoading &&
              state.data &&
              !data.manufacturers.error &&
              !data.devices.error)
          "
          container-type="FULL_WIDTH"
          class="scroll-to-form"
        >
          <FormKit
            v-if="state.data?.formieForm"
            id="repairshop-form"
            type="form"
            class="max-w-md"
            :actions="false"
            :incomplete-message="false"
            spellcheck="false"
            :config="{
              validationVisibility: 'blur',
              classes: {
                input: `${
                  useSiteIdent() !== SiteIdent.default ? 'rounded-corners' : ''
                }`,
              },
            }"
            @submit="onSubmit"
          >
            <ContainerBasic v-if="isBasicStep" container-type="PAGE">
              <div
                class="text-white rounded-alt-lg print:hidden bg-activesection-beta"
              >
                <form-repairshop-steps-basic :block-data="blockData" />
              </div>
            </ContainerBasic>

            <ContainerBasic
              v-else
              container-type="FULL_WIDTH"
              class="bg-white text-text-base"
            >
              <form-repairshop-checkout
                :submitted-successfully="state.submittedSuccessfully"
              />
            </ContainerBasic>

            <template v-if="shouldRenderHoneypotField">
              <input id="beesknees" type="hidden" value="" />
            </template>
          </FormKit>
        </ContainerBasic>
        <div v-else>
          <LoadingError />
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useRuntimeConfig } from '#app';
import {
  resetPriceData,
  usePriceData,
} from '~/components/form/repairshop/composables/usePriceData';
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import { useRepairshopData } from '~/components/form/repairshop/composables/useRepairshopData';
import { load } from 'recaptcha-v3';
import { useReservation } from '~/stores/useReserevation';
import { submit } from '~/components/form/repairshop/utils/mutation';
import {
  isFormValidForStep,
  loadForm,
} from '~/components/form/repairshop/utils/form';
import { RepairshopStepEnum } from '@/components/form/repairshop/utils/formStepEnum';
import ContainerBasic from '@/components/components/container-basic.vue';

import FormRepairshopStepsBasic from '~/components/form/repairshop/components/form-steps/basic.vue';
import FormRepairshopPricelist from '~/components/form/repairshop/components/price/list.vue';
import FormRepairshopCheckout from '~/components/form/repairshop/checkout.vue';
import FormRepairshopJsAnimation from '~/components/form/repairshop/js-animation.vue';
import LoaderForm from '~/components/loader/form.vue';
import { useCachedGet } from '~~/src/composables/dataFetching/genericFetchers.js';
import type { EventPage } from '~~/src/server/transformers/page/eventPage';
import type { Result as ManufacturersResult } from '~~/src/server/api/[site]/content/form/repairshop/manufacturers.get';
import type { Result as DeviceResult } from '~~/src/server/api/[site]/content/form/repairshop/devices.get';
import type { Result as FormResult } from '~~/src/server/api/[site]/content/form/[handle].get';
import type { Result as TagsResult } from '~~/src/server/api/[site]/content/form/repairshop/tags.get';
import { FormKit } from '@formkit/vue';
import type { FormBlockData } from '~/components/components/cms/form/blockType';
import { SiteIdent } from '~/utils/types';
import { handleLoadingError } from '~/utils/handleLoadingError';
import { LoadingError } from '@/complib';

const props = defineProps({
  blockData: {
    type: Object as PropType<FormBlockData>,
    required: true,
    default: {} as FormBlockData,
  },
  pageData: {
    type: Object as PropType<Record<string, any>>,
    required: false,
    default: null,
  },
});

const languageCode = useNuxtApp().$languageCode as string;
const pageDataInject = usePageData();
const config = useRuntimeConfig();
const data = useRepairshopData();
const { formData: form, resetFormData } = await useFormData();
const costs = usePriceData(languageCode);
const state = reactive<{
  data: FormResult | null;
  isLoading: boolean;
  isWaiting: boolean;
  submittedSuccessfully: boolean;
  captchas: { googleCaptcha: any };
}>({
  data: null,
  isLoading: true,
  isWaiting: false,
  submittedSuccessfully: false,
  captchas: { googleCaptcha: null },
});
const isBasicStep = computed(() => {
  return (
    useRouter().currentRoute.value.hash !== '#repairshop-address' &&
    useRouter().currentRoute.value.hash !== '#repairshop-overview' &&
    !state.submittedSuccessfully
  );
});
const site = useSiteIdent();

onBeforeMount(async () => {
  await redirectOnInvalidData(useRouter().currentRoute.value.hash);
  await Promise.all([loadManufacturers(), loadDevices()]);

  state.data = await loadForm(props.blockData.formHandle);
  state.isLoading = false;

  if (
    (
      state.data?.formieForm?.settings?.integrations as Array<{
        handle: string;
      }>
    )?.some((x) => x.handle === 'recaptcha')
  ) {
    state.captchas.googleCaptcha = await load(
      config.public.RECAPTCHA_SITE_KEY,
      { autoHideBadge: true },
    );
  }

  if (
    useRouter().currentRoute.value.hash === '#repairshop-basic' &&
    !state.submittedSuccessfully
  ) {
    setTimeout(() => {
      const element = document.querySelector('#repairshop-form');
      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 500);
  }
});

watch(
  () => useRouter().currentRoute.value.hash,
  async (newHash) => {
    await redirectOnInvalidData(newHash);
  },
);

async function redirectOnInvalidData(hash: string) {
  const redirectUri = pageDataInject?.value?.uri ?? props.pageData.uri;
  switch (hash) {
    case '#repairshop-address':
      if (!(await isFormValidForStep(RepairshopStepEnum.BASIC))) {
        useRouter().replace({
          path: '/' + redirectUri,
          hash: '',
        });
        return;
      }
      break;
    case '#repairshop-overview':
      if (!(await isFormValidForStep(RepairshopStepEnum.ADDRESS))) {
        useRouter().replace({
          path: '/' + redirectUri,
          hash: '',
        });
        return;
      }
      break;
    default:
      break;
  }
}

async function loadManufacturers() {
  data.value.manufacturers.isLoading = true;
  try {
    const { data: fetchData } = await useCachedGet<ManufacturersResult>(
      `/api/${site}/content/form/repairshop/manufacturers`,
    );

    if (!fetchData?.value?.repairshopManufacturers?.length) return;

    data.value.manufacturers.data = fetchData.value.repairshopManufacturers.map(
      (m) => {
        return { label: m.name, value: m.id, isOpen: false };
      },
    );

    data.value.manufacturers.isInitialized = true;
  } catch (e) {
    data.value.manufacturers.error = true;
    handleLoadingError(e);
  } finally {
    data.value.manufacturers.isLoading = false;
  }
}

async function loadDevices() {
  data.value.devices.isLoading = true;
  try {
    const { data: fetchData } = await useCachedGet<DeviceResult>(
      `/api/${site}/content/form/repairshop/devices`,
    );
    if (!fetchData?.value?.repairshopDevices?.length) {
      return;
    }

    fetchData.value.repairshopDevices.forEach((d) => {
      if (!(d.manufacturerId in data.value.devices.data)) {
        data.value.devices.data[d.manufacturerId] = [];
      }
      if (
        !data.value.devices.data[d.manufacturerId].find(
          (x: any) => x.handle === d.handle,
        )
      ) {
        data.value.devices.data[d.manufacturerId].push({
          label: d.label,
          value: d.id,
          price: d.price,
          handle: d.handle,
        });
      }
    });

    data.value.devices.isInitialized = true;
  } catch (e) {
    data.value.devices.error = true;
    handleLoadingError(e);
  } finally {
    data.value.devices.isLoading = false;
  }
}

const shouldRenderHoneypotField = computed<boolean>(() => {
  if (!state.data) {
    return false;
  }

  return state.data?.formieForm?.formFields.some((f) => {
    return f.handle === 'honeypot';
  });
});

async function onSubmit() {
  (document.activeElement as HTMLElement)?.blur();
  form.value.isFormSubmitting = true;
  const result = await submit(
    props.blockData.formHandle,
    state,
    getParsedMutationVariables(),
    site,
  );

  if (!result) {
    form.value.isFormSubmitting = false;
    return;
  }

  try {
    const { data: fetchData } = await useCachedGet<TagsResult>(
      `/api/${site}/content/form/repairshop/tags`,
    );
    const tags = fetchData.value?.tags ?? [];

    form.value.isFormSubmitting = false;

    const reservationStore = useReservation();
    await reservationStore.addReservation({
      formSubmissionResult: result,
      formDataRaw: Object.assign({}, form.value),
      pageDataRaw: pageDataInject?.value ?? props.pageData,
      entryDate:
        (pageDataInject?.value as EventPage)?.event?.date ??
        (props.pageData.value as EventPage)?.event.date,
      price: costs.value.subtotal,
      shippingCosts: costs.value.shipping,
      grossPrice: (costs.value.subtotal + costs.value.shipping) * 1.19,
      typeHandle: props.blockData.formHandle,
      tags:
        tags.map((x) => {
          return x.id;
        }) ?? [],
    });

    state.submittedSuccessfully = true;
    resetFormData();
    resetPriceData(languageCode);
    state.data = null;
    useRouter().push(useRouter().currentRoute.value.path + '/thank-you');
  } catch (e) {
    handleLoadingError(e);
  }
}

function getParsedMutationVariables() {
  const toolsObj: { rows: Record<string, any>[] } = { rows: [] };
  form.value.tools.forEach((tool) => {
    toolsObj.rows.push({
      manufacturerId: tool.manufacturer.value,
      deviceId: tool.device.value,
      serialNumber: tool.serialNumber,
      issueDescription: tool.infoText,
    });
  });

  return {
    tools: toolsObj,
    companyName: form.value.companyName,
    addressData: {
      street: form.value.addressData.street,
      houseNumber: form.value.addressData.houseNumber,
      zipCode: form.value.addressData.zipCode,
      city: form.value.addressData.city,
    },
    contactData: {
      customerNumber: form.value.contactData.customerNumber,
      customerEmail: form.value.contactData.customerEmail,
      customerPhone: form.value.contactData.customerPhone,
    },
    deliveryAddress: {
      deliveryCompanyName: form.value.differentDeliveryAddress
        ? form.value.deliveryData.companyName
        : form.value.companyName,
      deliveryStreet: form.value.differentDeliveryAddress
        ? form.value.deliveryData.addressData.street
        : form.value.addressData.street,
      deliveryHouseNumber: form.value.differentDeliveryAddress
        ? form.value.deliveryData.addressData.houseNumber
        : form.value.addressData.houseNumber,
      deliveryZipCode: form.value.differentDeliveryAddress
        ? form.value.deliveryData.addressData.zipCode
        : form.value.addressData.zipCode,
      deliveryCity: form.value.differentDeliveryAddress
        ? form.value.deliveryData.addressData.city
        : form.value.addressData.city,
    },
    honeypot: document.getElementById('beesknees')
      ? (<HTMLInputElement>document.getElementById('beesknees')).value
      : '',
  };
}
</script>

<style lang="postcss">
#repairshop-form {
  .custom-button {
    @apply w-full justify-center !mx-0 !px-4;
  }
  :deep(.hide-default-message .formkit-outer .formkit-messages) {
    @apply !hidden;
  }

  :deep(.country-zipcode-default-message .formkit-messages) {
    @apply !ml-4;
  }

  :deep(.custom-number-message .formkit-outer .formkit-messages) {
    @apply !max-w-[180px] !w-[180px] bg-[#ffffff99] rounded-b pl-[9px] py-[2px];
  }

  :deep([data-invalid] .custom-number-step) {
    @apply border-b-sign-red !border-b-[3px] rounded-bl-[0px] rounded-br-[0px];
  }

  :deep([data-invalid] .formkit-input) {
    @apply border-b-sign-red !border-b-[3px] z-500;
  }
  :deep([data-invalid] .v-select) {
    @apply border-b-sign-red border-b-[3px] z-500;
  }

  [checkbox] .formkit-input {
    @apply h-6 w-6 mr-4 border-border-light border-1 rounded-[3px];
  }

  :deep(.formkit-wrapper) {
    @apply !leading-[19px];
  }

  #myForm-incomplete {
    @apply hidden;
  }

  .formkit-inner > input,
  .formkit-inner > div,
  .formkit-input {
    @apply w-full;
  }

  .formkit-outer .formkit-messages {
    @apply bg-[#ffffff99] rounded-b pl-[9px] py-[2px];
  }
  .formkit-outer .formkit-message {
    @apply text-sign-red text-sm leading-xs;
  }

  .form-popup {
    @apply absolute flex justify-end h-0 top-[10px] right-4;
  }

  .multiline-input-text .formkit-messages {
    @apply !mt-[-9px];
  }

  .form-custom-popper {
    --popper-theme-background-color: #5c8a8e !important;
    --popper-theme-background-color-hover: #5c8a8e !important;
  }
}
</style>
