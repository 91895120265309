<template>
  <div
    class="flex flex-col w-full gap-x-md lg:flex-row touch:md:flex-row lg:px-sm touch:md:px-sm shrink-0"
  >
    <!-- Left col -->
    <div
      class="flex-initial max-w-[930px] w-full px-sm pt-lg lg:pt-2xl touch:md:pt-2xl"
    >
      <div>
        <div class="mt-xl mb-md lg:mb-lg touch:md:mt-lg">
          <Headline
            :has-decoration="true"
            :headline="t('repairshopForm.overview.headline')"
            level="h1"
          />
        </div>

        <!-- Address overview -->
        <div
          class="grid grid-cols-1 lg:grid-cols-2 touch:md:grid-cols-2 gap-md mb-xl"
        >
          <div
            class="relative border-[#CBCBCB] border-1 border-solid p-sm cursor-pointer rounded-alt-lg"
            @click="routeToAddress()"
          >
            <div class="absolute right-0">
              <BasicLink
                :label="t('repairshopForm.overview.edit')"
                :link="$router.currentRoute.value.path"
                hash="#repairshop-address"
                target="_self"
                icon="fas fa-chevron-right"
                :icon-position="EPosition.RIGHT"
                class="font-text text-primary-base pr-sm"
              />
            </div>
            <h4 class="mb-md font-deco-alt">
              {{ t('repairshopForm.overview.invoiceAddress') }}
            </h4>
            <div class="mb-md">
              <p>{{ formData.companyName }}</p>
              <p>
                {{ formData.addressData.street }}
                {{ formData.addressData.houseNumber }}
              </p>
              <p>
                {{ formData.addressData.zipCode }}
                {{ formData.addressData.city }}
              </p>
            </div>

            <div>
              <p v-if="formData.contactData.customerNumber">
                {{ formData.contactData.customerNumber }}
              </p>
              <p>{{ formData.contactData.customerEmail }}</p>
              <p>{{ formData.contactData.customerPhone }}</p>
            </div>
          </div>

          <div
            class="relative border-[#CBCBCB] border-1 border-solid p-sm cursor-pointer rounded-alt-lg"
            @click="routeToAddress()"
          >
            <div class="absolute right-0">
              <BasicLink
                :label="t('repairshopForm.overview.edit')"
                :link="$router.currentRoute.value.path"
                hash="#repairshop-address"
                target="_self"
                icon="fas fa-chevron-right"
                :icon-position="EPosition.RIGHT"
                class="font-text text-primary-base pr-sm"
              />
            </div>
            <h4 class="mb-md font-deco-alt">
              {{ t('repairshopForm.overview.deliveryAddress') }}
            </h4>
            <div>
              <p>
                {{
                  formData.differentDeliveryAddress
                    ? formData.deliveryData.companyName
                    : formData.companyName
                }}
              </p>
              <p>
                {{
                  formData.differentDeliveryAddress
                    ? formData.deliveryData.addressData.street +
                      ' ' +
                      formData.deliveryData.addressData.houseNumber
                    : formData.addressData.street +
                      ' ' +
                      formData.addressData.houseNumber
                }}
              </p>
              <p>
                {{
                  formData.differentDeliveryAddress
                    ? formData.deliveryData.addressData.zipCode +
                      ' ' +
                      formData.deliveryData.addressData.city
                    : formData.addressData.zipCode +
                      ' ' +
                      formData.addressData.city
                }}
              </p>
            </div>
          </div>
        </div>

        <!-- Item list -->
        <div class="mb-xl lg:mb-2xl touch:md:mb-2xl">
          <div class="grid grid-cols-12 gap-x-xs px-sm">
            <div class="col-span-6 font-bold lg:col-span-5 touch:md:col-span-5">
              {{ t('repairshopForm.overview.tool') }}
            </div>
            <div
              class="hidden font-bold lg:block touch:md:block lg:col-span-5 touch:md:col-span-5"
            >
              {{ t('repairshopForm.overview.issueDescription') }}
            </div>
            <div
              class="col-span-6 font-bold text-right lg:col-span-2 touch:md:col-span-2 lg:text-center touch:md:text-center"
            >
              {{ t('repairshopForm.overview.repairCosts') }}*
            </div>
          </div>

          <hr class="w-full h-[1px] bg-border-dark border-t-0 mt-sm" />

          <template v-for="tool of formData.tools" :key="tool.id">
            <div
              v-if="tool.device && tool.manufacturer"
              class="grid grid-cols-12 gap-x-xs px-sm mt-md"
            >
              <div class="col-span-9 lg:col-span-5 touch:md:col-span-5">
                <div class="flex items-start">
                  <img
                    :src="getIconByHandle(tool.device.handle)"
                    class="w-[60px] lg:w-[88px] touch:md:w-[88px] h-auto"
                    loading="lazy"
                    alt="Icon"
                  />
                  <div class="ml-sm lg:ml-md touch:md:ml-md">
                    <p class="font-bold break-all">{{ tool.device.label }}</p>
                    <p class="break-all">{{ tool.manufacturer.label }}</p>
                    <p v-if="tool.serialNumber.length > 0" class="break-all">
                      {{ t('repairshopForm.pricebox.serialNumber') }}:
                      {{ tool.serialNumber }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="order-last col-span-12 lg:order-none touch:md:order-none lg:col-span-5 touch:md:col-span-5 pt-sm lg:pt-0 touch:md:pt-0"
              >
                <TruncatedCollapsedText
                  :id="`info-${tool.id}`"
                  :text="tool.infoText"
                  text-color="text-text-base"
                />
              </div>
              <div
                class="col-span-3 font-bold text-right font-deco-alt lg:col-span-2 touch:md:col-span-2 lg:text-center touch:md:text-center"
              >
                {{ n(parseFloat(tool.device.price), 'currency') }}
              </div>
            </div>
          </template>
        </div>

        <!-- Price info text -->
        <RepairshopPriceInfo
          class="!text-[14px] mb-xl"
          classes="text-border-dark"
        />
      </div>
    </div>

    <!-- Pricebox -->
    <RepairshopFieldPriceBox last-step>
      <div class="mb-0 lg:mb-md touch:md:mb-md">
        <BasicButton
          classes="w-full bottom-0 z-10 lg:z-auto touch:md:z-auto fixed bottom-0 left-0 right-0 lg:relative touch:md:relative font-deco-alt"
          :label="t('repairshopForm.orderSubmitLabel')"
          :type="EButtonType.SUBMIT"
          :btn-style="EButtonStyle.CTA"
          :no-border-radius="!isDesktop"
          :btn-height="isDesktop ? '' : 'var(--sticky-btn-height)'"
          :disabled="formData.isFormSubmitting"
          :loading-animation="formData.isFormSubmitting"
          full-width
        />
      </div>
    </RepairshopFieldPriceBox>
  </div>
</template>
<script setup lang="ts">
import { getIconByHandle } from '~/components/form/repairshop/utils/iconMapper';
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import { EButtonType, EButtonStyle } from '@/@types/basic-button';
import { EPosition } from '@/@types/basic-link';

import BasicButton from '~~/src/components/components/core/basic-button/basic-button.vue';
import { Headline } from '@/complib';
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';
import RepairshopPriceInfo from '~/components/form/repairshop/components/price/info-text.vue';
import RepairshopFieldPriceBox from '~/components/form/repairshop/components/price/tile-view.vue';
import TruncatedCollapsedText from '~/components/form/repairshop/components/truncated-collapsed-text.vue';

const { t, n } = useTrans();
const { formData } = await useFormData();
const isDesktop = useIsDesktopView();

function routeToAddress() {
  useRouter().replace({
    path: useRouter().currentRoute.value.path,
    hash: '#repairshop-address',
  });
}
</script>
