<template>
  <div class="flex flex-col max-w-[930px] mx-auto py-xl bg-activesection-base">
    <div class="px-[30px] md:px-[240px]">
      <div class="mb-xl">
        <Headline
          :headline="t('repairshopForm.headline')"
          :has-decoration="true"
          level="h2"
          logo-color-main="#FFFFFF"
          logo-color-second="#FFFFFF"
          text-color="!text-white"
          stroke-color="#FFFFFF"
          class="fill-white stroke-white"
        />

        <WYSIWYGContentRaw
          v-if="useSiteIdent() === SiteIdent.default"
          class="pt-sm"
          :content="t('repairshopForm.introText')"
          :no-container-padding="true"
        />
      </div>

      <div class="w-full">
        <div>
          <div class="relative flex items-center mb-md">
            <HeadlineDashed
              class="whitespace-nowrap"
              headline="repairshopForm.toolFields.headline"
            />
          </div>

          <div>
            <FormKit id="repairshop-tools" type="group">
              <FormKit ref="tools" type="list">
                <div class="flex flex-col px-0">
                  <div
                    v-for="(row, index) in form.tools"
                    :key="row.id"
                    class="flex flex-col md:flex-row bg-[#ffffff19] mb-5 py-sm px-sm md:items-center rounded-alt-lg"
                  >
                    <div class="flex flex-col w-full">
                      <FormRepairshopFieldsManufacturerSelect
                        class="mb-sm"
                        :index="index"
                      />

                      <FormRepairshopFieldsDeviceSelect
                        class="mb-sm"
                        inner-class="!text-white"
                        :index="index"
                      />

                      <div class="mb-sm">
                        <FormKit
                          id="customer-number"
                          v-model="form.tools[index].serialNumber"
                          type="text"
                          :label="
                            t('repairshopForm.toolFields.serialNumber.label')
                          "
                          placeholder="123456789"
                          input-class="w-full input"
                          inner-class="relative pt-[5px]"
                          label-class="text-md !leading-[19px] font-bold"
                        />
                      </div>

                      <div>
                        <FormKit
                          id="issue-description"
                          v-model="form.tools[index].infoText"
                          type="textarea"
                          :label="t('repairshopForm.toolFields.infoText.label')"
                          input-class="w-full input !h-44 focus:!h-44"
                          inner-class="relative pt-[5px]"
                          validation="required"
                          validation-visibility="blur"
                          :validation-messages="{
                            required: t(
                              'repairshopForm.toolFields.infoText.errorLabel',
                            ),
                            validation: t(
                              'repairshopForm.toolFields.infoText.errorLabel',
                            ),
                          }"
                          label-class="text-md !leading-[19px] font-bold"
                          :outer-class="'multiline-input-text'"
                        />
                      </div>
                    </div>

                    <div
                      class="flex items-center justify-center h-full pl-0 pt-sm md:pb-0 md:pl-sm md:justify-end"
                    >
                      <BasicButtonIcon
                        icon="fal fa-circle-minus"
                        button-classes="bg-transparent"
                        :btn-style="EButtonStyle.CTA"
                        icon-classes="text-[1.5rem]"
                        classes="bg-transparent"
                        @click="handleRemoveEntry(index)"
                      />
                    </div>
                  </div>
                </div>
              </FormKit>
            </FormKit>
            <div class="w-full">
              <BasicButton
                label="Weiteres Instrument hinzufügen"
                classes="w-full mt-3 px-0"
                icon="far fa-plus"
                :btn-style="EButtonStyle.FORMREPEATER"
                :icon-position="EPosition.LEFT"
                :full-width="true"
                @click="handleAddEntry()"
              />

              <div
                v-if="showMaxInstrumentsHint"
                class="w-full text-center formkit-outer"
              >
                <ul class="formkit-messages">
                  <li class="formkit-message" data-message-type="validation">
                    {{ t('repairshopForm.maxInstrumentsHint') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <FormRepairshopFieldsPrice classes="font-deco-alt" />

          <div class="pt-sm mb-xl">
            <BasicButton
              classes="w-full px-sm md:px-0"
              :label="t('repairshopForm.submitLabel')"
              :type="EButtonType.BUTTON"
              :btn-style="EButtonStyle.CTA"
              btn-class="!mx-0"
              :loading-animation="isLoading"
              :disabled="isLoading"
              :full-width="true"
              @click="checkValidity"
            />
          </div>

          <div class="text-sm mb-xl">
            {{ t('forms.captcha.google.intro') }}
            <a
              class="underline"
              href="https://policies.google.com/privacy"
              target="_blank"
            >
              {{ t('forms.captcha.google.privacy') }}
            </a>
            &
            <a
              class="underline"
              href="https://policies.google.com/terms"
              target="_blank"
            >
              {{ t('forms.captcha.google.terms') }}.
            </a>
          </div>

          <RepairshopPriceInfo class="!text-white" classes="text-border-dark" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { EButtonStyle, EButtonType, EPosition } from '@/@types/basic-button';

import FormRepairshopFieldsManufacturerSelect from '@/components/form/repairshop/components/form-fields/manufacturer-select.vue';
import FormRepairshopFieldsDeviceSelect from '@/components/form/repairshop/components/form-fields/device-select.vue';
import FormRepairshopFieldsPrice from '~/components/form/repairshop/components/price/form-overview.vue';
import RepairshopPriceInfo from '@/components/form/repairshop/components/price/info-text.vue';
import { Headline, HeadlineDashed, WYSIWYGContentRaw } from '@/complib';
import BasicButton from '~~/src/components/components/core/basic-button/basic-button.vue';
import BasicButtonIcon from '@/components/components/core/basicButtonIcon/basic-button-icon.vue';

import type { ToolType } from '~/components/form/repairshop/utils/formTypes';
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import { useRedirectData } from '~/stores/useRedirectData';
import { FormKit } from '@formkit/vue';
import type { FormBlockData } from '~/components/components/cms/form/blockType';
import { SiteIdent } from '~/utils/types';

const props = defineProps({
  blockData: {
    type: Object as PropType<FormBlockData>,
    required: true,
    default: {} as FormBlockData,
  },
});

const pageData = usePageData();
const MAX_INSTRUMENTS = 10;
const { formData: form } = await useFormData();
const isLoading = ref(false);
const { t } = useTrans();
const tools = ref(null);
const showMaxInstrumentsHint = ref(false);

function handleAddEntry() {
  // We need to set submitted state to true for all repeater fields
  // Otherwise field will not be validated
  validateData();

  if (form.value?.tools?.length >= MAX_INSTRUMENTS) {
    showMaxInstrumentsHint.value = true;
    return;
  }

  form.value.tools.push({
    id: uuidv4() as string,
    manufacturer: null,
    device: null,
    serialNumber: '',
    infoText: '',
  } as ToolType);
}

function handleRemoveEntry(index: number) {
  if (form.value.tools.length > 1) {
    form.value.tools.splice(index, 1);
    showMaxInstrumentsHint.value = false;
  } else {
    form.value.tools[index].manufacturer = null;
    form.value.tools[index].device = null;
    form.value.tools[index].serialNumber = '';
    form.value.tools[index].infoText = '';
  }
}

async function checkValidity() {
  isLoading.value = true;
  const invalidElements: any[] = [];

  // We need to set submitted state to true for all repeater fields
  // Otherwise field will not be validated
  tools.value.node.children.forEach((field: any) => {
    field.context.state.submitted = true;
    if (!field.context.state.valid) {
      invalidElements.push(field);
    }
  });

  if (!tools.value.node.context.state.valid) {
    const firstInvalidFieldNode = document
      .getElementById(invalidElements[0].context.id)
      .closest('.formkit-outer');

    if (firstInvalidFieldNode) {
      firstInvalidFieldNode.scrollIntoView({
        behavior: 'smooth',
      });
    }
    isLoading.value = false;
    return;
  }

  isLoading.value = false;

  useRedirectData().setData<any>({
    key: useRoute().path,
    value: {
      source: useRoute().path,
      target: '/repairshop/checkout',
      data: { blockData: props.blockData, pageData: pageData.value },
    },
  });
  useRouter().push({
    path: '/repairshop/checkout',
    hash: '#repairshop-address',
    query: { sourceUrl: useRoute().path },
  });
}

function validateData() {
  tools.value.node.children.forEach((field: any) => {
    field.context.state.submitted = true;
  });
}
</script>
