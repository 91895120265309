<template>
  <div class="w-full text-border-dark px-sm md:px-0" :class="classes">
    <p><sup class="pr-[5px]">*</sup>{{ t('repairshopForm.footNoteText1') }}</p>
    <br />
    <p>
      {{ t('repairshopForm.footNoteText2') }}
    </p>
    <br />
    <p>
      <strong>{{ t('repairshopForm.priceGuarantee') }}:</strong>
      <br />{{ t('repairshopForm.footNoteText3') }}
    </p>
  </div>
</template>

<script setup lang="ts">
const { t } = useTrans();

defineProps({
  classes: {
    type: String,
    required: false,
    default: '',
  },
});
</script>
