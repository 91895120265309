<template>
  <div class="mt-xl mb-md">
    <div
      id="form-price"
      class="py-md bg-[#FFFFFFBF] basis-full h-full relative text-text-base flex justify-center ml-[-30px] mr-[-30px] md:ml-[-240px] md:mr-[-240px]"
    >
      <div
        class="mx-[20px] sm:px-0 sm:w-[450px] sm:max-w-[450px] sm:min-w-[450px]"
      >
        <div class="w-full">
          <div class="flex flex-row">
            <div class="basis-2/3 shrink-0">
              {{ t('repairshopForm.pricebox.subSum') }}
            </div>
            <div
              class="flex justify-end font-bold basis-1/3 shrink-0"
              :class="classes"
            >
              {{ n(subtotalCosts, 'currency') }}
            </div>
          </div>

          <div class="flex flex-row pt-sm">
            <div class="basis-2/3 shrink-0">
              {{ t('repairshopForm.pricebox.shippingCosts') }}
            </div>
            <div class="flex justify-end basis-1/3 shrink-0" :class="classes">
              {{ n(shippingCosts, 'currency') }}
            </div>
          </div>

          <div class="py-md">
            <hr class="w-full border-t-[1px] border-black" />
          </div>

          <div class="flex flex-row">
            <div class="basis-2/3 shrink-0">
              <p class="font-bold">
                {{ t('repairshopForm.pricebox.totalSum') }}
              </p>
              <p>{{ t('repairshopForm.pricebox.totalSumInfo') }}</p>
            </div>
            <div
              class="basis-1/3 shrink-0 flex justify-end font-bold text-[32px]"
              :class="classes"
            >
              {{ n(totalCosts, 'currency') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import {
  usePriceData,
  setPriceData,
} from '~/components/form/repairshop/composables/usePriceData';

defineProps({
  classes: {
    type: String,
    required: false,
    default: '',
  },
});

const languageCode = useNuxtApp().$languageCode;
const { formData } = await useFormData();
const costs = usePriceData(languageCode);
const { n, t } = useTrans();

setPriceData(formData.value.tools, languageCode);
const subtotalCosts = computed<number>(() => {
  return costs.value.subtotal;
});

const shippingCosts = computed<number>(() => {
  return costs.value.shipping;
});

const totalCosts = computed<number>(() => {
  return costs.value.total;
});

watch(formData.value.tools, () => {
  setPriceData(formData.value.tools, languageCode);
});
</script>
