<template>
  <div class="thm-container-basic__content">
    <Headline
      :headline="t('repairshopForm.pricelist')"
      :has-decoration="true"
      :logo-color="'#ffffff'"
      level="h2"
      :container-classes="'mb-md'"
    />

    <p class="text-center">
      {{ t('repairshopForm.repair-festpreis', { price: deviceMinPrice }) }}
    </p>

    <div class="my-lg px-sm md:px-0">
      <div
        v-if="
          !repairshopData.manufacturers.isLoading &&
          repairshopData.manufacturers.isInitialized &&
          !repairshopData.devices.isLoading &&
          repairshopData.devices.isInitialized
        "
      >
        <div
          v-for="manufacturer in repairshopData.manufacturers.data"
          :key="manufacturer.value"
        >
          <div
            v-if="repairshopData.devices.data[manufacturer.value]?.length >= 1"
            class="grid items-center grid-cols-12 border-white cursor-pointer py-2xs border-t-1 border-r-1 border-l-1 my-sm px-md"
            :class="[
              manufacturer.isOpen
                ? '!bg-activesection-base !text-white !font-bold'
                : '!bg-grey-lighter !text-text-base',
              {
                'rounded-md': useSiteIdent() === SiteIdent.default,
                'rounded-sm': useSiteIdent() !== SiteIdent.default,
              },
            ]"
            @click="toggleVisibility(manufacturer.value, $el)"
          >
            <p class="col-span-10">{{ manufacturer.label }}</p>
            <div v-show="manufacturer.isOpen" class="col-span-2 ml-auto">
              <Icon icon-class="fal fa-angle-up" classes="mx-auto" />
            </div>
            <div v-show="!manufacturer.isOpen" class="col-span-2 ml-auto">
              <Icon icon-class="fal fa-angle-down" classes="mx-auto" />
            </div>
          </div>
          <div
            v-if="manufacturer.isOpen"
            class="overflow-hidden bg-white text-text-base max-h-[100vh] pb-lg"
          >
            <div
              class="grid grid-cols-6 font-bold border-b-2 md:grid-cols-12 py-xs"
            >
              <div class="col-span-4 text-left md:col-span-9 pl-md">
                {{ t('repairshopForm.listTableHeaderDescription') }}
              </div>
              <div class="col-span-2 text-right md:col-span-3 pr-md">
                {{ t('repairshopForm.listTableHeaderPrice') }}
              </div>
            </div>

            <div
              v-for="(device, index) in repairshopData.devices.data[
                manufacturer.value
              ]"
              :key="index"
              :class="{
                'border-none':
                  index ===
                  repairshopData.devices.data[manufacturer.value].length - 1,
              }"
              class="grid grid-cols-6 border-b-2 md:grid-cols-12 py-xs"
            >
              <div class="col-span-4 text-left md:col-span-9 pl-md">
                {{ device.label }}
              </div>
              <div
                class="flex justify-end col-span-2 text-right md:col-span-3 md:text-center md:pl-md md:pr-20 pr-md"
              >
                {{ n(device.price, 'currency') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="bg-white border border-gray-300 border-solid p-sm animate-pulse"
      >
        <div class="h-[36px] bg-gray-300 mb-sm"></div>
        <div class="h-[36px] bg-gray-300 mb-sm"></div>
        <div class="h-[36px] bg-gray-300 mb-sm"></div>
        <div class="h-[36px] bg-gray-300"></div>
      </div>
    </div>

    <RepairshopPriceInfo class="text-sm" classes="text-border-dark" />
  </div>
</template>
<script setup lang="ts">
import { scrollToElement } from '@/helpers/scroll';
import { useRepairshopData } from '~/components/form/repairshop/composables/useRepairshopData';
import type { OptionType } from '@/components/form/repairshop/utils/formTypes';

import { Headline, Icon } from '@/complib';
import RepairshopPriceInfo from '~/components/form/repairshop/components/price/info-text.vue';
import { SiteIdent } from '~/utils/types';

const { t, n } = useTrans();
const repairshopData = useRepairshopData();

function toggleVisibility(id: number, el: Element): void {
  const manu = repairshopData.value.manufacturers.data.find(
    (m: any) => m.value === id,
  );
  if (!manu.isOpen) {
    accordionOpenHandler(id, el);
  } else {
    accordionCloseHandler(id);
  }
}

function accordionOpenHandler(accordionId: number, el: Element): void {
  scrollToElement(el, 200);
  accordionOpen(accordionId);
}

function accordionCloseHandler(accordionId: number): void {
  accordionClose(accordionId);
}
// Extend these functions and introduce a settings-prop if you need more control over when accordions auto-close
function accordionOpen(accordionId: number): void {
  repairshopData.value.manufacturers.data.map((accordion: any) => {
    accordion.isOpen = accordion.value === accordionId;
    return accordion;
  });
}

function accordionClose(accordionId: number): void {
  repairshopData.value.manufacturers.data.find(
    (x: any) => x.value === accordionId,
  ).isOpen = false;
}

const deviceMinPrice = computed(() => {
  if (
    !repairshopData.value.devices.isInitialized ||
    repairshopData.value.devices.isLoading
  ) {
    return 0;
  }

  if (Object.keys(repairshopData.value.devices.data).length === 0) {
    return 0;
  }

  // Get min price from repairshopData.value.devices.data
  const devicePrices = Object.values(repairshopData.value.devices.data)
    .flat()
    .map((device) => Number((device as OptionType).price));

  return Math.min(...devicePrices);
});
</script>

<style>
.thm-container-basic__content {
  @apply h-full w-full desktop:md:max-w-screen-md desktop:lg:max-w-screen-lg touch:md:max-w-screen-lg mx-auto;
}
</style>
