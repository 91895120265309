<template>
  <div class="multiselect-custom-wrapper">
    <FormKit
      :id="`manufacturer-select-${index}`"
      :type="multiselect"
      :label="t('repairshopForm.toolFields.manufacturer.label')"
      :placeholder="t('repairshopForm.toolFields.genericPlaceholder')"
      :value="form.tools[index].manufacturer"
      name="manufacturer"
      :initial-options="data.manufacturers.data"
      :disabled="data.manufacturers.isLoading"
      :is-loading="data.manufacturers.isLoading"
      :validation-messages="{
        required: t('repairshopForm.toolFields.manufacturer.errorLabel'),
        validation: t('repairshopForm.toolFields.manufacturer.errorLabel'),
      }"
      input-class="input rounded-corners"
      inner-class="relative pt-[5px]"
      validation-visibility="blur"
      validation="required"
      label-class="text-md !leading-[19px] font-bold"
      @input="handleValueChanged($event, index)"
    />
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import { useRepairshopData } from '~/components/form/repairshop/composables/useRepairshopData';
import { createInput, FormKit } from '@formkit/vue';
import customMultiselect from '~/components/form/select/customMultiselect.vue';

const { t } = useTrans();
const { formData: form } = await useFormData();
const data = useRepairshopData();

const props = defineProps({
  index: {
    type: Number,
    required: true,
    default: 0,
  },
});

const multiselect = createInput(customMultiselect, {
  props: ['initialOptions', 'label', 'placeholder', 'value', 'id', 'disabled'],
});

function handleValueChanged(event: any, index: number) {
  form.value.tools[index].manufacturer = event;
  resetFormData();
}

function resetFormData() {
  form.value.tools[props.index].device = null;
}
</script>
<style lang="postcss" scoped>
.multiselect-custom-wrapper :deep(.multiselect__tags) {
  @apply rounded-alt-sm;
}

.multiselect-custom-wrapper
  :deep(.formkit-outer:has(> .formkit-messages) .multiselect__tags) {
  @apply rounded-b-none;
}

.multiselect-custom-wrapper
  :deep(
    .multiselect-custom:has(> .multiselect--active:not(.multiselect--above))
      .multiselect__tags
  ) {
  @apply rounded-b-none !important;
}

.multiselect-custom-wrapper
  :deep(
    .multiselect-custom:has(> .multiselect--active.multiselect--above)
      .multiselect__tags
  ) {
  @apply rounded-t-none !important;
}

.multiselect-custom-wrapper :deep(.multiselect__option--highlight) {
  color: var(--thm-text-base);
  background: var(--thm-primary-lighter);
}

.multiselect-custom-wrapper :deep(.multiselect__option--highlight:after) {
  color: var(--thm-text-base);
  background: var(--thm-primary-lighter);
}
.multiselect-custom-wrapper
  :deep(.multiselect__option--highlight.multiselect__option--selected) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}

.multiselect-custom-wrapper
  :deep(.multiselect__option--highlight.multiselect__option--selected:after) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}

.multiselect-custom-wrapper :deep(.multiselect__spinner:before) {
  border-color: var(--thm-primary-base) transparent transparent !important;
}

.multiselect-custom-wrapper :deep(.multiselect__spinner:after) {
  border-color: var(--thm-primary-base) transparent transparent !important;
}
</style>
