<template>
  <div class="multiselect-custom-wrapper">
    <FormKit
      :id="`device-select-${index}`"
      :type="multiselect"
      :label="t('repairshopForm.toolFields.device.label')"
      :placeholder="t('repairshopForm.toolFields.genericPlaceholder')"
      :value="form.tools[index].device"
      name="manufacturer"
      :initial-options="options"
      :is-loading="data.devices.isLoading"
      :disabled="
        !form.tools[index].manufacturer ||
        form.tools[index].manufacturer.value.length === 0
      "
      :validation-messages="{
        required: t('repairshopForm.toolFields.device.errorLabel'),
        validation: t('repairshopForm.toolFields.device.errorLabel'),
      }"
      input-class="input rounded-corners"
      :inner-class="`relative pt-[5px] ${innerClass}`"
      validation-visibility="blur"
      validation="required"
      label-class="text-md !leading-[19px] font-bold"
      @input="handleValueChanged($event, index)"
    />
  </div>
</template>

<script setup lang="ts">
import type { OptionType } from '~/components/form/repairshop/utils/formTypes';
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import { useRepairshopData } from '~/components/form/repairshop/composables/useRepairshopData';
import { createInput, FormKit } from '@formkit/vue';
import customMultiselect from '~/components/form/select/customMultiselect.vue';

const { t } = useTrans();
const { formData: form } = await useFormData();
const data = useRepairshopData();
const options = ref<OptionType[]>([]);

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  innerClass: {
    type: String,
    required: false,
    default: '',
  },
});

const multiselect = createInput(customMultiselect, {
  props: [
    'initialOptions',
    'label',
    'placeholder',
    'value',
    'id',
    'disabled',
    'sublabel',
  ],
});

watch(
  () => form.value.tools[props.index]?.manufacturer,
  (newManufacturer, oldManufacturer) => {
    if (newManufacturer !== oldManufacturer) {
      if (!newManufacturer) {
        options.value = [];
        return;
      }

      options.value = data.value.devices.data[
        form.value.tools[props.index].manufacturer.value
      ] as OptionType[];
    }
  },
);

onMounted(() => {
  if (form.value.tools[props.index]?.manufacturer) {
    options.value = data.value.devices.data[
      form.value.tools[props.index].manufacturer.value
    ] as OptionType[];
  }
});

function handleValueChanged(event: any, index: number) {
  form.value.tools[index].device = event;
}
</script>
<style lang="postcss" scoped>
.multiselect-custom-wrapper :deep(.multiselect__tags) {
  @apply rounded-alt-sm;
}

.multiselect-custom-wrapper :deep(.multiselect--disabled) {
  @apply rounded-alt-sm;
}

.multiselect-custom-wrapper
  :deep(.formkit-outer:has(> .formkit-messages) .multiselect__tags) {
  @apply rounded-b-none;
}

.multiselect-custom-wrapper
  :deep(
    .multiselect-custom:has(> .multiselect--active:not(.multiselect--above))
      .multiselect__tags
  ) {
  @apply rounded-b-none !important;
}

.multiselect-custom-wrapper
  :deep(
    .multiselect-custom:has(> .multiselect--active.multiselect--above)
      .multiselect__tags
  ) {
  @apply rounded-t-none !important;
}

.multiselect-custom-wrapper :deep(.multiselect__option--highlight) {
  color: var(--thm-text-base);
  background: var(--thm-primary-lighter);
}

.multiselect-custom-wrapper :deep(.multiselect__option--highlight:after) {
  color: var(--thm-text-base);
  background: var(--thm-primary-lighter);
}
.multiselect-custom-wrapper
  :deep(.multiselect__option--highlight.multiselect__option--selected) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}

.multiselect-custom-wrapper
  :deep(.multiselect__option--highlight.multiselect__option--selected:after) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}

.multiselect-custom-wrapper :deep(.multiselect__spinner:before) {
  border-color: var(--thm-primary-base) transparent transparent !important;
}

.multiselect-custom-wrapper :deep(.multiselect__spinner:after) {
  border-color: var(--thm-primary-base) transparent transparent !important;
}

.formkit-outer:has(> .formkit-messages) .rounded-corners {
  @apply rounded-b-none;
}
</style>
