<template>
  <div
    class="flex-initial max-w-[451px] shrink-0 w-full mx-auto text-text-base"
    :class="{
      'bg-[#346E7314]': useSiteIdent() === SiteIdent.default,
      'bg-[#fce5f0]': useSiteIdent() !== SiteIdent.default,
    }"
  >
    <div
      class="px-md pt-md lg:pt-[155px] touch:md:pt-[155px] pb-md lg:pb-0 touch:md:pb-0"
    >
      <div>
        <h4
          class="mb-md"
          :class="{
            'text-[20px]': useSiteIdent() === SiteIdent.default,
            'font-pero font-bold text-[22px]':
              useSiteIdent() !== SiteIdent.default,
          }"
        >
          {{ t('repairshopForm.pricebox.summaryHeadline') }}
        </h4>
        <div class="grid grid-cols-2 mb-sm">
          <div>
            <p>
              {{ formData.tools.length }}
              {{
                formData.tools.length !== 1
                  ? t('repairshopForm.pricebox.repairOrderMulti')
                  : t('repairshopForm.pricebox.repairOrder')
              }}
            </p>
          </div>
          <div class="font-bold text-right font-deco-alt">
            {{ n(costs.subtotal, 'currency') }}
          </div>
        </div>

        <div class="grid grid-cols-2">
          <div>
            <p>
              {{ formData.tools.length > 0 ? 1 : 0 }}
              {{ t('repairshopForm.pricebox.shippingCosts') }}
            </p>
          </div>
          <div class="text-right font-deco-alt">
            {{ n(costs.shipping, 'currency') }}
          </div>
        </div>

        <hr
          class="my-sm w-full h-[1px] border-t-0"
          :class="[
            $siteIdent.startsWith('minilu')
              ? 'bg-border-dark'
              : 'bg-border-light',
          ]"
        />

        <div class="grid grid-cols-2 mb-sm lg:mb-md touch:md:mb-md">
          <div>
            <div class="font-bold">
              {{ t('repairshopForm.pricebox.totalSum') }}
            </div>
            <div class="text-sm text-[#707070]">
              {{ t('repairshopForm.pricebox.totalSumInfo') }}
            </div>
          </div>
          <div class="font-bold text-right font-deco-alt">
            {{ n(costs.total, 'currency') }}
          </div>
        </div>

        <div class="grid grid-cols-2 mb-sm lg:mb-md touch:md:mb-md">
          <div>
            <p>
              {{ t('repairshopForm.pricebox.vatInfo', { tax: costs.tax }) }}
            </p>
          </div>
          <div class="text-right font-deco-alt">
            {{ n(costs.total * (costs.tax / 100), 'currency') }}
          </div>
        </div>

        <div class="grid grid-cols-2 mb-sm lg:mb-md touch:md:mb-md">
          <div>
            <div class="font-bold">
              {{ t('repairshopForm.pricebox.totalSumGross') }}
            </div>
            <div class="text-sm text-[#707070]">
              {{ t('repairshopForm.pricebox.totalSumGrossInfo') }}
            </div>
          </div>
          <div class="text-3xl font-bold text-right font-deco-alt">
            {{ n(costs.total * (1 + costs.tax / 100), 'currency') }}
          </div>
        </div>
      </div>

      <slot />

      <div v-if="!lastStep" class="py-md">
        <h4 class="font-deco-alt">
          {{ t('repairshopForm.pricebox.repairServicesHeadline') }}
        </h4>

        <hr
          class="lg:hidden touch:md:hidden w-full mt-sm border-t-[1px] border-dashed border-border-light"
        />

        <template v-for="tool of formData.tools" :key="tool.id">
          <div class="flex mt-md mb-md last:mb-0">
            <img
              :src="getIconByHandle(tool.device.handle)"
              class="w-[60px]"
              loading="lazy"
              alt=""
            />
            <div class="w-full px-sm lg:px-md touch:md:px-md">
              <span class="block font-bold">{{ tool.device.label }}</span>
              <span class="block">{{ tool.manufacturer.label }}</span>
              <span v-if="tool.serialNumber.length > 0" class="block">
                {{ t('repairshopForm.pricebox.serialNumber') }}:
                {{ tool.serialNumber }}
              </span>
            </div>
            <div
              class="text-right font-deco-alt"
              :class="{
                'text-[18px] font-bold': useSiteIdent() !== SiteIdent.default,
              }"
            >
              {{ n(parseFloat(tool.device.price), 'currency') }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="lastStep"
      class="text-[14px] bg-white lg:bg-transparent touch:md:bg-transparent px-md pt-md lg:py-0 touch:md:my-0 pb-xl"
    >
      <Trans keypath="forms.termsDescriptionRepairshop" tag="span">
        <a href="/agb" target="_blank" class="font-bold text-primary-base">
          {{ t('forms.agb') }}
        </a>
        <a
          href="/datenschutz"
          target="_blank"
          class="font-bold text-primary-base"
        >
          {{ t('forms.privacy') }}
        </a>
      </Trans>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getIconByHandle } from '~/components/form/repairshop/utils/iconMapper';
import { usePriceData } from '~/components/form/repairshop/composables/usePriceData';
import { useFormData } from '~/components/form/repairshop/composables/useFormData';
import Trans from '@/components/Trans.vue';
import { SiteIdent } from '~/utils/types';

const { t, n } = useTrans();
const languageCode = useNuxtApp().$languageCode;
const costs = usePriceData(languageCode);
const { formData } = await useFormData();

defineProps({
  lastStep: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
