<template>
  <div class="px-sm py-md md:px-md md:pt-xl md:pb-lg xl:px-0">
    <Headline
      :headline="t('repairshopForm.fastHelpSuitcaseHeadline')"
      :has-decoration="true"
      :logo-color="'#ffffff'"
      level="h2"
      :container-classes="'mb-lg stroke-border-border-dark'"
    />

    <ContainerBasic container-type="PAGE">
      <div class="grid xl:grid-cols-12 gap-md">
        <div
          class="xl:col-span-8 bg-background-base p-sm w-full sm:h-fit rounded-alt-lg desktop:md:h-[340px] touch:md:h-full desktop:lg:h-[500px] desktop:xl:h-full"
        >
          <div class="w-full h-full">
            <iframe
              id="animFrame"
              ref="animFrame"
              :src="iframeSrc"
              scrolling="no"
              class="w-full h-full"
            >
            </iframe>
          </div>
        </div>
        <div class="xl:col-span-4 bg-background-base rounded-alt-lg">
          <div>
            <ul
              id="animNavigation"
              class="p-sm columns-1 md:columns-2 xl:columns-1"
            >
              <li id="step_1" class="steps stepActive" @click="seekAnim(1)">
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >1.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep1') }}
              </li>
              <li id="step_2" class="steps" @click="animStep2">
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >2.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep2') }}
              </li>
              <li id="step_3" class="steps" @click="animStep3">
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >3.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep3') }}
              </li>
              <li
                id="step_4"
                class="border-b-0 steps md:border-b md:border-border-light xl:border-b-0"
                @click="animStep4"
              >
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >4.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep4') }}
              </li>
              <li id="step_5" class="steps" @click="animStep5">
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >5.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep5') }}
              </li>
              <li id="step_6" class="steps" @click="animStep6">
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >6.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep6') }}
              </li>
              <li id="step_7" class="border-b steps" @click="animStep7">
                <Icon icon-class="far fa-chevron-right" class="mr-xs" />
                <strong class="pr-xs md:pr-0"
                  >7.
                  <span class="hidden md:inline-block">{{
                    t('repairshopForm.step')
                  }}</span></strong
                >
                <span class="hidden md:inline-block mx-xs">-</span>
                {{ t('repairshopForm.animationStep7') }}
              </li>
              <li>
                <CallToAction
                  :block-data="ctaBlock"
                  class="pt-sm !my-0 mt-sm"
                  :button-class="`mt-[5px] !w-full cursor-pointer ${
                    useSiteIdent() !== SiteIdent.default
                      ? 'font-pero text-lg !rounded-alt-2xl'
                      : '!rounded-sm'
                  }`"
                ></CallToAction>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ContainerBasic>
  </div>
</template>

<script setup lang="ts">
import { Headline, Icon } from '~/complib';
import ContainerBasic from '@/components/components/container-basic.vue';
import CallToAction from '@/components/block/cta/CallToAction.vue';
import type { CTAData } from '@/components/block/cta/blockTypes';
import { BlockTypeHandle } from '@/components/block/types';
import { ECallToActionBtnType } from '~~/src/@types/call-to-action-btn-type';
import { SiteIdent } from '~/utils/types';

const iframeSrc =
  useSiteIdent() === SiteIdent.default
    ? '/animVDV/vdv_anim.html'
    : useNuxtApp().$languageCode !== 'nl'
      ? '/animMiniluDE/minilu_anim.html'
      : '/animMiniluNL/minilu_anim.html';

const animFrame = ref(null);
const compositionKey = '3DAA48EE68734E318F5FE5CF06042E91';

useIntersectionObserver(animFrame, ([{ isIntersecting }]) => {
  const iframeWindow = animFrame.value.contentWindow;
  if (!iframeWindow || (iframeWindow && !('AdobeAn' in iframeWindow))) {
    return;
  }
  const stage = iframeWindow['AdobeAn']
    .getComposition(compositionKey)
    .getStage();
  if (stage && isIntersecting) {
    stage.seek(1);
    stage.play();
  }
});

const animStep2 = () =>
  useSiteIdent() === SiteIdent.default ? seekAnim(133) : seekAnim(58);

const animStep3 = () =>
  useSiteIdent() === SiteIdent.default ? seekAnim(193) : seekAnim(118);

const animStep4 = () =>
  useSiteIdent() === SiteIdent.default ? seekAnim(274) : seekAnim(179);

const animStep5 = () =>
  useSiteIdent() === SiteIdent.default ? seekAnim(343) : seekAnim(331);

const animStep6 = () =>
  useSiteIdent() === SiteIdent.default ? seekAnim(410) : seekAnim(392);

const animStep7 = () =>
  useSiteIdent() === SiteIdent.default ? seekAnim(489) : seekAnim(452);

function playAnim() {
  animFrame.value.contentWindow['AdobeAn']
    .getComposition(compositionKey)
    .getStage()
    .play();
}

function seekAnim(frameNumber: number) {
  animFrame.value.contentWindow['AdobeAn']
    .getComposition(compositionKey)
    .getStage()
    .seek(frameNumber);
  playAnim();
}

const { t } = useTrans();

const ctaBlock: CTAData = {
  id: '0',
  typeHandle: BlockTypeHandle.CTA,
  type: ECallToActionBtnType.MAIN_PATH,
  btnOrientation: 'center',
  label: t('repairshopForm.animationCTA'),
  textInverted: false,
  scrollToForm: true,
  uri: '',
  target: '_blank',
};
</script>

<style scoped lang="postcss">
.steps {
  @apply p-xs md:p-[21px] border-x border-t border-border-light text-sm cursor-pointer flex items-baseline;
}
.stepActive {
  @apply bg-primary-base text-white;
}
</style>
